import React, { createContext, useCallback, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { appConfig } from 'config/app';
import { KeyedObject } from 'interfaces/global.interface';
import jwtDecode from 'jwt-decode';

import { CategotyTypeId } from 'modules/authorization/pages/Register/RegisterUserForm';

import api from 'shared/services/api';
import { Wallet } from 'shared/services/withdrawals.service';

export interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
  redirectTo?: string | null;
}

export interface User {
  id: string;
  name: string;
  email: string;
  username: string;
  phone: string;
  avatar_url: string | null;
  banner_url: string | null;
  enterprise: {
    id?: string;
    name: string;
    about: string;
    friendly_name: string;
    phone: string;
    email: string;
    start_date: string;
    address: string;
    identify: string;
    avatar_url: string | null;
    banner_url: string | null;
    permission: {
      name: string;
      description: string;
      roles: string[];
    };
    wallet: Wallet;
  };
  category_type: CategotyTypeId;
}

interface AuthContextData {
  signIn(credentials: SignInCredentials, prevUrl?: string): Promise<void>;
  signOut(): void;
  setData(data: AuthState): void;
  user: User;
  token: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

// eslint-disable-next-line
export function signOutForce({ forceRedirect = false }: { forceRedirect?: boolean } = {}): void {
  localStorage.removeItem('@sombank:token');
  localStorage.removeItem('@sombank:user');

  if (forceRedirect) {
    const prevUrl = `${window.location.pathname}${window.location.search}`;
    localStorage.setItem('@sombank:prevUrl', prevUrl);

    window.location.replace('/login');
  }
}

function validateToken(token: string): boolean {
  if (!token) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(token);

  return decoded.exp > Date.now() / 1000;
}

const AuthProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@sombank:token');
    const user = localStorage.getItem('@sombank:user');

    const isValidAccessToken = token && user && validateToken(token);

    if (isValidAccessToken) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password, redirectTo }) => {
    const response = await api.post('/v1/sessions', {
      username: email,
      password,
    });

    const { user, token } = response.data;

    localStorage.setItem('@sombank:token', token);

    api.defaults.headers.common.Authorization = `Bearer ${token}`;

    const profileResponse = await api.get('/v1/users/profile');

    localStorage.setItem(
      '@sombank:user',
      JSON.stringify({
        ...user,
        phone: profileResponse?.data?.phone,
        avatar_url: profileResponse?.data?.avatar_url,
      }),
    );

    setData({
      token,
      user: {
        ...user,
        phone: profileResponse?.data?.phone,
        avatar_url: profileResponse?.data?.avatar_url,
      },
    });

    // TODO: Entender o que o prevUrl faz, troquei para o redirect To.
    // const prevUrl = localStorage.getItem('@sombank:prevUrl');

    const redirect = !!redirectTo ? appConfig.defaultPath + redirectTo : appConfig.defaultPlatformPath;

    navigate(redirect, { replace: true });

    localStorage.removeItem('@sombank:prevUrl');
  }, []);

  const signOut = useCallback(() => {
    signOutForce();

    setData({} as AuthState);

    navigate(appConfig.loginPath, { replace: true });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        setData,
        user: data.user,
        token: data.token,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
